<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 版本列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button style="margin-right: 5px" v-if="hasAuth('SaveClientVersion')"
                           type="primary"
                           icon="el-icon-plus"
                           class="handle-del"
                           @click="$router.push('/client/version/edit')">发布新版本
                </el-button>
                <el-popover placement="top-start" trigger="hover" content="状态">
                    <template #reference>
                        <el-select v-model="query.state" placeholder="状态" class="handle-select">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option key="1" label="已确认" :value="1"></el-option>
                            <el-option key="0" label="未确认" :value="0"></el-option>
                        </el-select>
                    </template>
                </el-popover>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    :height="common.calcTableHeight(350)"
                    header-cell-class-name="table-header"
            >
                <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                <el-table-column prop="version" label="版本号" align="center"></el-table-column>
                <el-table-column prop="clientId" label="客户端" align="center" width="150">
                    <template #default="scope">
                        <span><span class="success">{{!scope.row.child?0:scope.row.child.filter(e=>e.state===1).length}}</span>/{{!scope.row.child?0:scope.row.child.length}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.state===1" class="success">已确认</span>
                        <span v-else>待确认</span>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="更新内容" align="center"></el-table-column>
                <el-table-column prop="updateDate" label="更新时间" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.updateDate">{{scope.row.updateDate.toDate('yyyy-MM-dd')}}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" class-name="table-op" width="260" align="center"
                                 v-if="hasAuth('UpdateClientVersion','DeleteClientVersion')">
                    <template #default="scope">
                        <el-button  v-if="hasAuth('GetClientVersion') && scope.row.state===1"
                                type="text"
                                icon="el-icon-view"
                                @click="$router.push({path:'/client/version/edit',query:{id:scope.row.id}})">查看
                        </el-button>
                        <el-button v-if="hasAuth('UpdateClientVersion') && scope.row.state===0"
                                          type="text"
                                          icon="el-icon-edit"
                                          @click="$router.push({path:'/client/version/edit',query:{id:scope.row.id}})">编辑
                        </el-button>
                        <el-button v-if="hasAuth('DeleteClientVersion') && scope.row.state===0"
                                          type="text"
                                          icon="el-icon-delete"
                                          class="red"
                                          @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.page"
                        :page-size="query.rows"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";

    export default {
        name: "ClientVersionList",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                form: {},
                pageTotal: 0,
                query: {
                    state: null,
                    page: 1,
                    rows: 30
                },
                loading: false,
                servers: [],
                clients: [],
                client: {id: null},
            };
        },
        created() {
            this.init();
        },
        methods: {
            init(){
                this.getData();
            },
            getData() {
                if (this.client.id) {
                    this.query.clientId = this.client.id;
                }
                http.post("/client/version/list", this.query).then(data => {
                    data = data.data;
                    if (data) {
                        this.tableData = data.rows;
                        this.pageTotal = data.total;
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            // 触发搜索按钮
            handleSearch() {
                this.query.page = 1;
                this.getData();
            },
            //查找数据内容
            findArray(arr, id) {
                return common.arrayFindReturn(arr, id);
            },
            // 删除操作
            handleDelete(index, row) {
                // 二次确认删除
                this.$confirm("确定要删除吗？", "提示", {
                    type: "warning"
                })
                    .then(() => {
                        http.post("/client/version/delete", {id: row.id}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success("删除成功");
                                this.getData();
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            // 分页导航
            handlePageChange(val) {
                this.query.page = val;
                this.getData();
            },
        },
        watch: {
            $route() {
                if (this.$route.name === "ClientVersionList") {
                    //初始化
                    this.init();
                }
            }
        },
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 120px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }
</style>
